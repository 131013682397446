/* Cores */
:root {
    --azul-base: #007BFF;
    --azul-claro: #66B2FF;
    --azul-escuro: #004C9A;
    --cinza-claro: #f5f8fa;
    --cinza-escuro: #6C757D;
    --branco: #FFFFFF;
    --preto: #000000;
    --color-titulo: #181c32;
  }
  
  /* Estilos globais */
  body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: var(--cinza-claro);
    color: var(--preto);
  }
  
    
  a {
    color: var(--azul-base);
  }
  
  a:hover {
    color: var(--azul-escuro);
  }
  
  .btn {
    background-color: var(--azul-base);
    border-color: var(--azul-base);
  }
  
  .btn:hover {
    background-color: var(--azul-escuro);
    border-color: var(--azul-escuro);
  }
  
  .card {
    border-color: var(--azul-base);
  }

    
  .navbar,
  .navbar-brand {
    background-color: var(--azul-base);
    color: var(--branco) !important;
  }
  